import _conversion from "./conversion";
import _index from "./index";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toMsFilter = void 0;
var conversion_1 = _conversion;
var index_1 = _index;
/**
 * Returns the color represented as a Microsoft filter for use in old versions of IE.
 */

function toMsFilter(firstColor, secondColor) {
  var color = new index_1.TinyColor(firstColor);
  var hex8String = "#" + conversion_1.rgbaToArgbHex(color.r, color.g, color.b, color.a);
  var secondHex8String = hex8String;
  var gradientType = color.gradientType ? "GradientType = 1, " : "";

  if (secondColor) {
    var s = new index_1.TinyColor(secondColor);
    secondHex8String = "#" + conversion_1.rgbaToArgbHex(s.r, s.g, s.b, s.a);
  }

  return "progid:DXImageTransform.Microsoft.gradient(" + gradientType + "startColorstr=" + hex8String + ",endColorstr=" + secondHex8String + ")";
}

exports.toMsFilter = toMsFilter;
export default exports;