import _util from "./util";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.numberInputToObject = exports.parseIntFromHex = exports.convertHexToDecimal = exports.convertDecimalToHex = exports.rgbaToArgbHex = exports.rgbaToHex = exports.rgbToHex = exports.hsvToRgb = exports.rgbToHsv = exports.hslToRgb = exports.rgbToHsl = exports.rgbToRgb = void 0;
var util_1 = _util; // `rgbToHsl`, `rgbToHsv`, `hslToRgb`, `hsvToRgb` modified from:
// <http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript>

/**
 * Handle bounds / percentage checking to conform to CSS color spec
 * <http://www.w3.org/TR/css3-color/>
 * *Assumes:* r, g, b in [0, 255] or [0, 1]
 * *Returns:* { r, g, b } in [0, 255]
 */

function rgbToRgb(r, g, b) {
  return {
    r: util_1.bound01(r, 255) * 255,
    g: util_1.bound01(g, 255) * 255,
    b: util_1.bound01(b, 255) * 255
  };
}

exports.rgbToRgb = rgbToRgb;
/**
 * Converts an RGB color value to HSL.
 * *Assumes:* r, g, and b are contained in [0, 255] or [0, 1]
 * *Returns:* { h, s, l } in [0,1]
 */

function rgbToHsl(r, g, b) {
  r = util_1.bound01(r, 255);
  g = util_1.bound01(g, 255);
  b = util_1.bound01(b, 255);
  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h = 0;
  var s = 0;
  var l = (max + min) / 2;

  if (max === min) {
    s = 0;
    h = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;

      case g:
        h = (b - r) / d + 2;
        break;

      case b:
        h = (r - g) / d + 4;
        break;

      default:
        break;
    }

    h /= 6;
  }

  return {
    h: h,
    s: s,
    l: l
  };
}

exports.rgbToHsl = rgbToHsl;

function hue2rgb(p, q, t) {
  if (t < 0) {
    t += 1;
  }

  if (t > 1) {
    t -= 1;
  }

  if (t < 1 / 6) {
    return p + (q - p) * (6 * t);
  }

  if (t < 1 / 2) {
    return q;
  }

  if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }

  return p;
}
/**
 * Converts an HSL color value to RGB.
 *
 * *Assumes:* h is contained in [0, 1] or [0, 360] and s and l are contained [0, 1] or [0, 100]
 * *Returns:* { r, g, b } in the set [0, 255]
 */


function hslToRgb(h, s, l) {
  var r;
  var g;
  var b;
  h = util_1.bound01(h, 360);
  s = util_1.bound01(s, 100);
  l = util_1.bound01(l, 100);

  if (s === 0) {
    // achromatic
    g = l;
    b = l;
    r = l;
  } else {
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return {
    r: r * 255,
    g: g * 255,
    b: b * 255
  };
}

exports.hslToRgb = hslToRgb;
/**
 * Converts an RGB color value to HSV
 *
 * *Assumes:* r, g, and b are contained in the set [0, 255] or [0, 1]
 * *Returns:* { h, s, v } in [0,1]
 */

function rgbToHsv(r, g, b) {
  r = util_1.bound01(r, 255);
  g = util_1.bound01(g, 255);
  b = util_1.bound01(b, 255);
  var max = Math.max(r, g, b);
  var min = Math.min(r, g, b);
  var h = 0;
  var v = max;
  var d = max - min;
  var s = max === 0 ? 0 : d / max;

  if (max === min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;

      case g:
        h = (b - r) / d + 2;
        break;

      case b:
        h = (r - g) / d + 4;
        break;

      default:
        break;
    }

    h /= 6;
  }

  return {
    h: h,
    s: s,
    v: v
  };
}

exports.rgbToHsv = rgbToHsv;
/**
 * Converts an HSV color value to RGB.
 *
 * *Assumes:* h is contained in [0, 1] or [0, 360] and s and v are contained in [0, 1] or [0, 100]
 * *Returns:* { r, g, b } in the set [0, 255]
 */

function hsvToRgb(h, s, v) {
  h = util_1.bound01(h, 360) * 6;
  s = util_1.bound01(s, 100);
  v = util_1.bound01(v, 100);
  var i = Math.floor(h);
  var f = h - i;
  var p = v * (1 - s);
  var q = v * (1 - f * s);
  var t = v * (1 - (1 - f) * s);
  var mod = i % 6;
  var r = [v, q, p, p, t, v][mod];
  var g = [t, v, v, q, p, p][mod];
  var b = [p, p, t, v, v, q][mod];
  return {
    r: r * 255,
    g: g * 255,
    b: b * 255
  };
}

exports.hsvToRgb = hsvToRgb;
/**
 * Converts an RGB color to hex
 *
 * Assumes r, g, and b are contained in the set [0, 255]
 * Returns a 3 or 6 character hex
 */

function rgbToHex(r, g, b, allow3Char) {
  var hex = [util_1.pad2(Math.round(r).toString(16)), util_1.pad2(Math.round(g).toString(16)), util_1.pad2(Math.round(b).toString(16))]; // Return a 3 character hex if possible

  if (allow3Char && hex[0].startsWith(hex[0].charAt(1)) && hex[1].startsWith(hex[1].charAt(1)) && hex[2].startsWith(hex[2].charAt(1))) {
    return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0);
  }

  return hex.join("");
}

exports.rgbToHex = rgbToHex;
/**
 * Converts an RGBA color plus alpha transparency to hex
 *
 * Assumes r, g, b are contained in the set [0, 255] and
 * a in [0, 1]. Returns a 4 or 8 character rgba hex
 */
// eslint-disable-next-line max-params

function rgbaToHex(r, g, b, a, allow4Char) {
  var hex = [util_1.pad2(Math.round(r).toString(16)), util_1.pad2(Math.round(g).toString(16)), util_1.pad2(Math.round(b).toString(16)), util_1.pad2(convertDecimalToHex(a))]; // Return a 4 character hex if possible

  if (allow4Char && hex[0].startsWith(hex[0].charAt(1)) && hex[1].startsWith(hex[1].charAt(1)) && hex[2].startsWith(hex[2].charAt(1)) && hex[3].startsWith(hex[3].charAt(1))) {
    return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0) + hex[3].charAt(0);
  }

  return hex.join("");
}

exports.rgbaToHex = rgbaToHex;
/**
 * Converts an RGBA color to an ARGB Hex8 string
 * Rarely used, but required for "toFilter()"
 */

function rgbaToArgbHex(r, g, b, a) {
  var hex = [util_1.pad2(convertDecimalToHex(a)), util_1.pad2(Math.round(r).toString(16)), util_1.pad2(Math.round(g).toString(16)), util_1.pad2(Math.round(b).toString(16))];
  return hex.join("");
}

exports.rgbaToArgbHex = rgbaToArgbHex;
/** Converts a decimal to a hex value */

function convertDecimalToHex(d) {
  return Math.round(parseFloat(d) * 255).toString(16);
}

exports.convertDecimalToHex = convertDecimalToHex;
/** Converts a hex value to a decimal */

function convertHexToDecimal(h) {
  return parseIntFromHex(h) / 255;
}

exports.convertHexToDecimal = convertHexToDecimal;
/** Parse a base-16 hex value into a base-10 integer */

function parseIntFromHex(val) {
  return parseInt(val, 16);
}

exports.parseIntFromHex = parseIntFromHex;

function numberInputToObject(color) {
  return {
    r: color >> 16,
    g: (color & 65280) >> 8,
    b: color & 255
  };
}

exports.numberInputToObject = numberInputToObject;
export default exports;