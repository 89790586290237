import _index from "./index";
import _cssColorNames from "./css-color-names";
import _readability from "./readability";
import _toMsFilter from "./to-ms-filter";
import _fromRatio from "./from-ratio";
import _formatInput from "./format-input";
import _random from "./random";
import _interfaces from "./interfaces";
import _conversion from "./conversion";
var exports = {};

var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
var index_1 = _index;

__exportStar(_index, exports);

__exportStar(_cssColorNames, exports);

__exportStar(_readability, exports);

__exportStar(_toMsFilter, exports);

__exportStar(_fromRatio, exports);

__exportStar(_formatInput, exports);

__exportStar(_random, exports);

__exportStar(_interfaces, exports);

__exportStar(_conversion, exports); // kept for backwards compatability with v1


exports.default = index_1.tinycolor;
export default exports;
export const __esModule = exports.__esModule,
      tinycolor = exports.tinycolor,
      TinyColor = exports.TinyColor,
      names = exports.names,
      mostReadable = exports.mostReadable,
      isReadable = exports.isReadable,
      readability = exports.readability,
      toMsFilter = exports.toMsFilter,
      legacyRandom = exports.legacyRandom,
      fromRatio = exports.fromRatio,
      isValidCSSUnit = exports.isValidCSSUnit,
      stringInputToObject = exports.stringInputToObject,
      inputToRGB = exports.inputToRGB,
      bounds = exports.bounds,
      random = exports.random,
      numberInputToObject = exports.numberInputToObject,
      parseIntFromHex = exports.parseIntFromHex,
      convertHexToDecimal = exports.convertHexToDecimal,
      convertDecimalToHex = exports.convertDecimalToHex,
      rgbaToArgbHex = exports.rgbaToArgbHex,
      rgbaToHex = exports.rgbaToHex,
      rgbToHex = exports.rgbToHex,
      hsvToRgb = exports.hsvToRgb,
      rgbToHsv = exports.rgbToHsv,
      hslToRgb = exports.hslToRgb,
      rgbToHsl = exports.rgbToHsl,
      rgbToRgb = exports.rgbToRgb;